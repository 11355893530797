import React from "react";

import { graphql, type PageProps } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import {
  GridWithSidebarStyling,
  isBrowser,
  SEO,
  ToCSidebar,
  useToC,
} from "@parataxic/shared-ui";

import { ParataxicMDXProvider } from "../components/MDXProvider";
import { PopoverSiteLink } from "../components/Popover";
import { TheoryLinkList } from "../components/Term";

type MDXTermPageProps = PageProps<
  GatsbyTypes.RequireFields<GatsbyTypes.MDXTermPageQuery, "mdxTerm">,
  GatsbyTypes.SitePageContext
>;

const MDXTermPage: React.FC<MDXTermPageProps> = ({
  data: { mdxTerm },
  //{
  //   mdxTerm: { mdx: { term: {} } },
  // }
}) => {
  const mdx = mdxTerm.mdx;
  const content = mdxTerm.mdx?.body;
  const title = mdx?.frontmatter?.title;
  useToC({ querySelector: "#content nav div ul li a" });
  const referrer =
    isBrowser() && window.location.search.match(/\?referrer=(.*)/)?.[1];
  const theorySlug = referrer ?? mdxTerm.theories?.[0]?.slug;
  const backLink = theorySlug ? (
    <PopoverSiteLink
      href={`${theorySlug}`}
      style={{
        display: "inline-block",
        opacity: "0.4",
        marginTop: "-1rem",
        fontSize: "sm",
      }}
    >
      {"< "}Go Back
    </PopoverSiteLink>
  ) : (
    <a
      href="#"
      onClick={(e: React.MouseEvent) => {
        e.preventDefault();
        history.back();
      }}
      style={{
        opacity: "0.4",
        marginTop: "-1rem",
        display: "inline-block",
      }}
    >
      {"< "}Go Back
    </a>
  );
  return (
    <div style={GridWithSidebarStyling} id="content" role="article">
      {title && <SEO title={title} />}
      <ToCSidebar
        mdx={mdx}
        variant="grid-with-sidebar"
        indent="0.15rem"
        initialDepth={0}
      />
      <ParataxicMDXProvider
        style={{
          alignSelf: "start",
          gridColumn: 1,
          gridRow: 1,
          maxWidth: "936px",
          minWidth: "0",
        }}
      >
        {backLink}
        <h1>{title}</h1>
        <hr style={{ color: "var(--color-gray-300)" }} />
        {mdxTerm.theories?.length > 0 && (
          <>
            <span
              style={{ fontStyle: "italic", color: "var(--color-gray-500)" }}
            >
              Mentioned in{" "}
              <TheoryLinkList
                theories={mdxTerm.theories}
                linkProps={{
                  tippyProps: { placement: "bottom" },
                }}
              />
            </span>
          </>
        )}
        <MDXRenderer>{content}</MDXRenderer>
      </ParataxicMDXProvider>
    </div>
  );
};

export const query = graphql`
  query MDXTermPage($id: String) {
    mdxTerm(id: { eq: $id }) {
      id
      mdx: parent {
        ... on Mdx {
          id
          body
          excerpt(pruneLength: 160)
          frontmatter {
            title
          }
          tableOfContents(maxDepth: 4)
          wordCount {
            sentences
          }
        }
      }
      authors {
        fullName
        handle
        slug
      }
      theories {
        id
        title
        slug
      }
      synonyms {
        slug
        title
      }
      belongsTo {
        id
        title
      }
      compareTo {
        id
        title
        theories {
          id
          title
          slug
        }
      }
    }
  }
`;

export default MDXTermPage;
